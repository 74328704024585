<template>
  <div class="container">
    <div class="content-contanier">
      <div class="content-box">
        <div class="content">
          <swiper
            class="swiper-view"
            ref="mySwiper"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="(item,index) in list"
              :key="index"
            >
              <div
                class="item"
                :style="{backgroundImage:`url('${item.url}')`}"
              >
                <p class="desc">{{item.desc}}</p>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="download-swiper-wrap">
                <div class="left"></div>
                <div class="line"></div>
                <div class="right">
                  <a class="download-btn" href="https://apps.apple.com/cn/app/id1559167934">
                    <span class="icon" ><img src="../assets/ios-icon.png" alt="" style="width: 14px; height: 17px;"></span>
                    <span>iOS下载</span>
                  </a>
                  <a class="download-btn" download="快快抢.apk" href="https://cdn.jiguo.com/app/kkq.apk">
                    <span class="icon"><img src="../assets/android-icon.png" alt="" style="width: 14px; height: 17px;"></span>
                    <span>Android下载</span>
                  </a>
<!--                  <div class="download-btn" @click="handleHistoryDownLoad">-->
<!--                    <span class="icon"><img src="../assets/android-icon.png" alt="" style="width: 14px; height: 17px;"></span>-->
<!--                    <span>Android历史下载</span>-->
<!--                  </div>-->
                </div>
              </div>
            </swiper-slide>
            <div
              class="swiper-pagination"
              slot="pagination"
            >
            </div>
          </swiper>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      swiperOptions: {
        loop: true,
        speed: 1000,
        mousewheel: true,
        direction: "vertical",
        width: window.innerWidth,
        height: window.innerHeight,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        }
      },
      list: [
        // {
        //   url: require("../assets/miaosha.png"),
        //   desc:
        //     "快快抢聚合了多个平台的秒杀抢购商品，飞天茅台，热门手机，潮鞋潮玩，一网打尽！",
        // },
        // {
        //   url: require("../assets/xiadan.png"),
        //   desc:
        //     "快快抢安卓版提供自动下单功能，跳转后自动下单，提交后锁定库存，帮你提高成功率！",
        // },
        // {
        //   url: require("../assets/qianggou.png"),
        //   desc:
        //     "快快抢提供抢购清单，提前加入清单配置抢购，随时进入抢购，拒绝手忙脚乱！",
        // },
        // {
        //   url: require("../assets/qingdan.png"),
        //   desc:
        //     "快快抢基于各平台的时间，为用户提供毫秒级低误差跳转，帮你快人一步下单！",
        // },
        // {
        //   url: require("../assets/lianjie.png"),
        //   desc:
        //     "除了快快抢挑选的热门抢购商品，用户还可以自己添加淘宝、京东、拼多多商品参与抢购，抢你喜欢！",
        // },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    scrollPage(page){
      this.swiper.slideTo(page,0)
    },
    handleHistoryDownLoad() {
      this.$router.push({ path: '/download' })
    }
  },
  mounted() {
   
  }
};
</script>

<style lang="scss">
.content-contanier {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .content-box {
    width: 100%;
    height: 100%;
  }
  .content {
    width: 100%;
    height: 100%;
  }
}
.swiper-view {
  height: 100%;

  .slide-child-wrap{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
.container {
  .swiper-container-vertical > .swiper-pagination-bullets {
    right: 10%;
  }
  .swiper-pagination-bullet {
    width: 2px;
    height: 42px;
    background-color: #d3e4e8;
    border-radius: 0;
  }
  // .swiper-container-vertical
  //   > .swiper-pagination-bullets
  //   .swiper-pagination-bullet:first-of-type {
  //   margin-top: 0;
  // }
  .swiper-pagination-bullet-active {
    background-color: #3bbcbf;
  }
  .swiper-wrapper {
    transition-timing-function: ease-in-out;
  }
}

.swiper-view{
  .item {
    width: 864px;
    height: 482px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: auto 439px;
    .desc {
      width: 241px;
      position: absolute;
      left: 571px;
      top: 69px;
      font-size: 12px;
      line-height: 17px;
    }
  }
}


.download-swiper-wrap {
  width: 864px;
  height: 482px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    width: 132px;
    height: 92px;
    background: url("../assets/download.png") no-repeat center center;
    background-size: cover;
  }
  .line {
    width: 2px;
    height: 92px;
    background-color: #e4e8e9;
    margin: 0 75px;
  }
  .right{
    .download-btn{
      text-decoration: none;
      cursor: pointer;
      .icon{
        margin: 0 10px;
      }
      margin: 6px 0;
      background: #3BBCBF;
      border-radius: 18px;
      width: 149px;
      height: 33px; 
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
    }
  }
}

</style>