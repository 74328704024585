<template>
  <div class="download-wrap">
    <p class="title">发版历史</p>

    <div class="history-wrap">
      <div class="item" v-for="(item, index) in historyData" :key="item.id">
        <div class="software">
          <img src="../assets/history-btn.png" alt="">
          <span class="name">{{ item.app_name }} {{ item.version }}</span>
          <span class="time">{{ item.version_time }}</span>
          <span v-if="index == 0" class="tag">new</span>
        </div>

        <div class="line"></div>

        <div class="description">
          <div v-for="(itemD, indexD) in item.version_desc" :key="indexD">
            {{itemD}}
          </div>
        </div>

        <a 
          class="btn"
          :href="item.version_url"
        >
          <span>
            下载
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      historyData: [],
      host: process.env.NODE_ENV == 'production' ? '//kkqiang.com' : '//kkq.jiguo.com'
     };
  },
  computed: {
    
  },
  methods: {
    // 获取快快抢历史版本
    getAppHistory() {
      // kkqiang.com
      this.$http.get(this.host + "/v1/init/get-app-history").then(({ data }) => {
        this.historyData = this.handleData(data.result.data)
      });
    },
    handleData(data) {
       return data.map((item, index) => {
        item.version_desc = item.version_desc.split('\n')
        return item
      })
    }
  },
  mounted() {
    this.getAppHistory()
  }
};
</script>

<style lang="scss" scoped>
.download-wrap{
  width: 100%;
  height: 100%;
  .title{
    margin-top: 80px;
    padding: 40px 0;
    // padding: calc(100% - 100px) 0;
    text-align: center;
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2C2C2C;
    line-height: 20px;
  }

  .history-wrap::-webkit-scrollbar {
    width:6px; 
  }
  .history-wrap::-webkit-scrollbar-track {
  background-color:#fff;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
  }
  .history-wrap::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
  }

  .history-wrap{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    // height: 450px;
    height: calc(100% - 300px);
    overflow-y: auto;

   
    .item{
      margin-top: 33px;
      width: 98%;
      height: 88px;
      display: flex;
      border-bottom: 1px solid #D1E3DD;

      .software{
        width: 300px;
        height: 50px;
        display: flex;
        align-items: center;
        
        img{
          width: 37px;
          height: 37px;
        }
        .name{
          padding-left: 12px;
          width: 65px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2C2C2C;
          line-height: 17px;
        }
        .time{
          width: 95px;
          padding-left: 52px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C2C2C;
          line-height: 17px;
        }
        .tag{
          margin-left: 6px;
          width: 32px;
          height: 13px;
          border-radius: 7px;
          border: 1px solid #3BBCBF;

          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C2C2C;
          line-height: 13px;
          text-align: center;
        }
      }

      .line{
        width: 1px;
        height: 53px;
        background: #D1E3DD;
        margin-left: 50px;
      }

      .description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50px;
        min-width: 108px;
        margin-left: 50px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2C2C2C;
        line-height: 17px;
      }
      
      .btn{
        margin-right: 10px;
        margin-left: auto;
        width: 53px;
        height: 50px;
        display: flex;
        align-items: center;
        text-decoration: none;
        span{
          width: 53px;
          height: 26px;
          background: #3BBCBF;
          border-radius: 14px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
}
</style>