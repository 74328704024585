import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import main from '../components/main'
import download from '../components/download'
import consult from '../components/consult'
import login from '../components/login'
import register from '../components/register'

const routes = [
  { path: '/', component: main },
	{ path: '/download', component: download },
	{ path: '/consult', component: consult },
	{ path: '/login', component: login },
	{ path: '/register', component: register },
]

const router = new Router({
  routes // (缩写) 相当于 routes: routes
})

export {
  router
}