<template>
  <div class="register">
    <div class="title">用户注册</div>
    <div class="mt" style="margin-top:20px;"></div>
    <el-row class="form">
      <el-form label-width="100px" :model="form">
        <el-form-item label="邮箱: ">
          <el-input placeholder="请输入邮箱" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input placeholder="请输入密码" v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" style="width: 100%">
          <el-input placeholder="请再次输入密码" v-model="form.repassword"></el-input>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input placeholder="请输入姓名" v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="身份证：">
          <el-input placeholder="请输入身份证" v-model="form.usercode"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input placeholder="请输入手机号" v-model="form.tel"></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-button type="primary" @click="indexHandle">注册</el-button>
      <el-button type="primary" @click="indexHandle">回到首页</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "register",
  data(){
    return {
      form: {
        tel: "",
        password: "",
        repassword: "",
        username: "",
        usercode: "",
        email: "",
      }
    }
  },
  methods:{
    indexHandle(){
      this.$router.push({ path: '/' })
      this.$nextTick(() => {
        this.$refs.currRouter.scrollPage(1)
      })
    }
  }
}
</script>

<style scoped>
  .register{
    width: 600px;
    height: 550px;
    position: fixed;
    z-index: 1;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
    background-color: white;
    padding: 30px;
  }

</style>