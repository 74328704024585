<template>
  <div class="register">
    <div class="title">用户登录</div>
    <div class="mt" style="margin-top:20px;"></div>
    <el-row class="form">
      <el-form label-width="100px" :model="form">

        <el-form-item label="手机号：">
          <el-input v-model="form.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>

      </el-form>
    </el-row>
    <el-row>
      <el-button type="primary" @click="indexHandle">登录</el-button>
      <el-button type="primary" @click="indexHandle">回到首页</el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "register",
  data(){
    return {
      form: {
        tel: "",
        password: ""
      }
    }
  },
  methods:{
    indexHandle(){
      this.$router.push({ path: '/' })
      this.$nextTick(() => {
        this.$refs.currRouter.scrollPage(1)
      })
    }
  }
}
</script>

<style scoped>
.register{
  width: 600px;
  height: 350px;
  position: fixed;
  z-index: 1;
  bottom: 300px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  background-color: white;
  padding: 30px;
}

</style>