<template>
  <div class="container">
    <header>
      <div class="main-header">
        <div class="left">
          <img
            src="../assets/logo.png"
            alt=""
          >
        </div>
        <div class="right">
          <div
            class="home"
            @click="scrollStart"
          >首页</div>
<!--          <div-->
<!--            class="feedback consult"-->
<!--            @click="handleHistoryConsult"-->
<!--          >行业资讯</div>-->
<!--          <a-->
<!--            href="https://support.qq.com/products/311479"-->
<!--            class="feedback"-->
<!--            target="_blank"-->
<!--          >反馈</a>-->
<!--          <div-->
<!--            class="feedback consult"-->
<!--          ><span @click="loginHandle">登录</span> | <span @click="registerHandle">注册</span></div>-->

          <div
            class="download-btn"
            @click="scrollEnd"
          >下载APP</div>
        </div>
      </div>
    </header>
    <router-view ref="currRouter"></router-view>
    <footer>
      <p>Copyright © 2022 快快抢 All rights reserved. 京ICP备18047487号 | 京ICP备18047487号-4A</p>
      <p>北京翰鼎翔睿科技有限公司</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      swiperOptions: {
        loop: true,
        speed: 1000,
        mousewheel: true,
        direction: "vertical",
        width: window.innerWidth,
        height: window.innerHeight,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        }
      },
      list: [
        {
          url: require("../assets/miaosha.png"),
          desc:
            "快快抢聚合了多个平台的秒杀抢购商品，飞天茅台，热门手机，潮鞋潮玩，一网打尽！",
        },
        {
          url: require("../assets/xiadan.png"),
          desc:
            "快快抢安卓版提供自动下单功能，跳转后自动下单，提交后锁定库存，帮你提高成功率！",
        },
        {
          url: require("../assets/qianggou.png"),
          desc:
            "快快抢提供抢购清单，提前加入清单配置抢购，随时进入抢购，拒绝手忙脚乱！",
        },
        {
          url: require("../assets/qingdan.png"),
          desc:
            "快快抢基于各平台的时间，为用户提供毫秒级低误差跳转，帮你快人一步下单！",
        },
        {
          url: require("../assets/lianjie.png"),
          desc:
            "除了快快抢挑选的热门抢购商品，用户还可以自己添加淘宝、京东、拼多多商品参与抢购，抢你喜欢！",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    
  },
  methods: {
    handleHistoryConsult() {
      this.$router.push({ path: '/consult' })
    },
    loginHandle(){
      this.$router.push({ path: '/login' })
    },
    registerHandle(){
      this.$router.push({ path: '/register' })
    },
    scrollStart(){
      this.$router.push({ path: '/' })
      this.$nextTick(() => {
        this.$refs.currRouter.scrollPage(1)
      })
    },
    scrollEnd(){
      if(this.$route.path != '/') {
        this.$router.push({ path: '/' })
      }
      this.$nextTick(() => {
        this.$refs.currRouter.scrollPage(6)  
      })
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;

  .main-header {
    width: 1100px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .left {
    height: 30px;
    img {
      height: 100%;
    }
  }
  .right {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 17px;
  }
  .home {
    cursor: pointer;
  }
  .feedback {
    margin-left: 36px;
    text-decoration: none;
    color: #2c2c2c;
  }

  .consult:hover{
    cursor: pointer;
  }

  .download-btn {
    width: 88px;
    height: 33px;
    margin-left: 36px;
    line-height: 33px;
    text-align: center;
    background-color: #3bbcbf;
    color: #fff;
    border-radius: 33px;
    cursor: pointer;
  }
}
.content-contanier {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .content-box {
    width: 100%;
    height: 100%;
  }
  .content {
    width: 100%;
    height: 100%;
  }
}

footer {
  position: fixed;
  bottom: 42px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #808080;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  z-index: 1;
}
</style>