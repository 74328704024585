<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from "./components/index";
export default {
  name: "App",
  metaInfo: {
    title: "快快抢-热门值得买商品导购",
    htmlAttrs: {
      lang: "zh-CN",
    },
    meta: [
      { name: "description", content: "汇聚茅台潮鞋超值秒杀好物" },
      {
        name: "keywords",
        content:
          "快快抢,什么值得买,值得买,慢慢买,聚好抢,AI潮流,得物,识货,毒物",
      },
    ],
  },
  components: {
    Index,
  },
};
</script>

<style lang="scss">
body {
  font-family: LarkEmojiFont, LarkChineseQuote, -apple-system,
    BlinkMacSystemFont, Helvetica Neue, Tahoma, PingFang SC, Microsoft Yahei,
    Arial, Hiragino Sans GB, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  height: 100%;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-variant-ligatures: no-common-ligatures;
  color: #2c2c2c;
  background: #f4f8f9 url("./assets/bg.png") no-repeat right 105px top;
  background-size: 482px auto;
  -webkit-print-color-adjust: exact;
  overflow: hidden;
}
/* html {
  @include root-font-size();
} */
</style>
