<template>
  <div class="download-wrap">
    <p class="title">行业资讯</p>

    <div class="history-wrap">
      <a
          href="javascript:;"
         class="item"
          v-for="(item, index) in historyData"
          :key="item.id"
          @click="showModel=true"
          v-if="item.id != 32 && index<16"
      >
        <div class="software">
          <img :src="item.bg_color" alt="">
          <span class="name">{{ item.title }} {{ item.tag }}</span>
          <span class="time">{{ item.username }}</span>
        </div>
      </a>
    </div>

    <div class="model" v-show="showModel">
      <div class="top">扫码付款</div>
      <div class="close" @click="showModel=false">x</div>
      <div class="content">(行业资讯为付费内容，0.5元/条)</div>
      <img src="@/assets/kkq/img/pay.png" class="img" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      showModel: false,
      historyData: [],
      host: process.env.NODE_ENV == 'production' ? '//kkqiang.com' : '//kkq.jiguo.com'
    };
  },
  computed: {

  },
  methods: {

    // 获取快快抢历史版本
    getAppHistory() {
      // kkqiang.com
      this.$http.get(this.host + "/v1/utils/get-user-tutorial").then(({ data }) => {
        this.historyData = this.handleData(data.result.data)
      });
    },
    handleData(data) {
      return data
    }
  },
  mounted() {
    this.getAppHistory()
  }
};
</script>

<style lang="scss" scoped>

.model{
  width: 300px;
  height: 350px;

  position: fixed;
  z-index: 1;
  bottom: 200px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  background-color: white;

  .close{
    font-size: 20px;
    position: relative;
    top:-50px;
    text-align: right;
    right: 5px;
    color: white;

    &:hover{
      cursor: pointer;
    }
  }

  .content{
    height: 30px;
    line-height: 30px;
    color: red;
    font-size: 14px;
  }

  .top{
    height: 50px;
    line-height: 50px;
    font-size: 32px;
    color: white;
    background-color: red;
  }

  .img{
    width: 250px;
    height: 250px;
    display: block;
    margin: 0 auto;
  }
}

.download-wrap{
  width: 100%;
  height: 100%;
  .title{
    margin-top: 80px;
    padding: 40px 0;
    // padding: calc(100% - 100px) 0;
    text-align: center;
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2C2C2C;
    line-height: 20px;
  }

  .history-wrap::-webkit-scrollbar {
    width:6px;
  }
  .history-wrap::-webkit-scrollbar-track {
    background-color:#fff;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }
  .history-wrap::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius:2em;
  }

  .history-wrap{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    // height: 450px;
    height: calc(100% - 300px);
    overflow-y: auto;


    .item{
      text-decoration: none;
      margin-top: 33px;
      width: 98%;
      height: 88px;
      display: flex;
      border-bottom: 1px solid #D1E3DD;

      .software{
        width: 600px;
        height: 50px;
        display: flex;
        align-items: center;

        img{
          width: 57px;
          height: 57px;
        }
        .name{
          padding-left: 12px;
          width: 365px;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2C2C2C;
          line-height: 17px;
        }
        .time{
          width: 195px;
          padding-left: 52px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C2C2C;
          line-height: 17px;
        }
        .tag{
          margin-left: 6px;
          width: 32px;
          height: 13px;
          border-radius: 7px;
          border: 1px solid #3BBCBF;

          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C2C2C;
          line-height: 13px;
          text-align: center;
        }
      }

      .line{
        width: 1px;
        height: 53px;
        background: #D1E3DD;
        margin-left: 50px;
      }

      .description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 50px;
        min-width: 108px;
        margin-left: 50px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2C2C2C;
        line-height: 17px;
      }

      .btn{
        margin-right: 10px;
        margin-left: auto;
        width: 53px;
        height: 50px;
        display: flex;
        align-items: center;
        text-decoration: none;
        span{
          width: 53px;
          height: 26px;
          background: #3BBCBF;
          border-radius: 14px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
}
</style>